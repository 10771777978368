function dvf_template(template,values){
    if(template != '' && values){
        var ret = template;
        for (var key in values) {
            // check for IF tag
            if(ret.match(new RegExp('{{#' + key + '}}', 'g'))){
                if(values[key] != '' && values[key] != false){
                    ret = ret.replace(new RegExp('{{#' + key + '}}|{{/' + key + '}}', 'g'), "");
                } else {
                    ret = ret.replace(new RegExp('{{#' + key + '}}.*?{{/' + key + '}}', 'g'), "");
                }
            } 

            ret = ret.replace(new RegExp('{{' + key + '}}', 'g'), values[key]);
        }
        return ret;
    }
}

lazyload_timeout = null;

function initLazyload(){
    $(function(){
        lazyLoad();
        $(window).resize(function() {
            window.clearTimeout(lazyload_timeout);
            lazyload_timeout = window.setTimeout(function(){
                lazyLoad();
            }, 500);
        });
        $(window).scroll(function() {
            window.clearTimeout(lazyload_timeout);
            lazyload_timeout = window.setTimeout(function(){
                lazyLoad();
            }, 500);
        });
    });
}


function lazyLoad(){
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    
    //console.log('LazyLoad start: ' + viewportBottom);
    
    $('.lazyload:not(.loaded)').each(function(){
        var that = $(this);
        var elementTop = $(that).offset().top;
        var elementBottom = elementTop + $(that).outerHeight();
        //console.log('LazyLoad Object: ' + viewportBottom + ' > ' + elementTop + ' && ' + elementBottom + ' > ' + viewportTop);
        if(viewportBottom > elementTop && elementBottom > viewportTop){
            var target = $(that).attr('data-target');
            var sizes = $(that).attr('data-lazyload').split('-');
            if(target == 'bg'){
                $(that).css('background-image', $(that).css('background-image').replace(sizes[0],sizes[1])).addClass('loaded');
            } else {
                $(that).attr('src', $(that).attr('src').replace(sizes[0],sizes[1])).on('load',function() {
                    $(that).addClass('loaded');
                });
            }
        }
    });
}
