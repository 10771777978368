var dvGui = function() {
    
    var initNewsletter = function(){
        $('#newslettertest').click(function (){
            $('#newslettertest_message').hide('fast');
            var newslettertestid = $('#newslettertestid').val();
            var newslettertestemail = $('#newslettertestemail').val();
            $.ajax({
               type: "POST",
               url: "/newsletter/newslettertest",
               data: '&newslettertestid=' + newslettertestid + '&newslettertestemail=' + newslettertestemail,
               success: function(content) {
                   $('#newslettertestmail_message').html(content);
                   $('#newslettertestmail_message').show('fast');
               }
           });
        });
        $('.nl-sort').sortable();
        
        $('.nl-linked a').each(function(){
            if(!$(this).hasClass('has-action')){
                $(this).click(function (){
                    $(this).parent().remove();
                });
               $(this).addClass('has-action');
           }
        });
    }

    
    /********* PUBLIC *********************************************************/
    return {
        init: function() {
        },
        initNewsletter: function() {
            initNewsletter();
        }
    }
}();                   