var initpage = '';

var dvMain = function() {
    
    var initPages = function(){
        switch(initpage){
            default:
            break;
            case 'alumnifeature':
                dvGuiForms.init();
            break;
            case 'newsletteredit':
                dvGui.initNewsletter();
            break;
        }
    }

    
    /********* PUBLIC *********************************************************/
    return {
        init: function(ipg) {
            console.log('NETZhdk Admin 1.1: ' + ipg);
            initpage = ipg;
            
            if(ipg){
                initPages();
            }
        }
    }
}();