$(function() {    
    $('.dvgui-datatable').each(function(){
        var ordercol = $(this).attr('data-ordercol');
        if(!ordercol) ordercol = 1;
        var ascdesc = $(this).attr('data-ascdesc');
        if(!ascdesc) ascdesc = 'asc';
        var num = $(this).attr('data-num');
        if(!num) num = '30';
        
        var attr = {
            language: {
                aria: {
                    sortAscending: ': aufsteigend sortieren',
                    sortDescending: ': absteigend sortieren'
                },
                emptyTable: 'Keine Tabelle verfügbar',
                info: 'Zeige _START_ bis _END_ von _TOTAL_ Einträgen',
                infoEmpty: 'Keine Einträge gefunden',
                infoFiltered: '(gefiltert von insgesamt _MAX_ Einträgen)',
                lengthMenu: '_MENU_ &nbsp;&nbsp;Einträge',
                search: 'Suche:&nbsp;',
                zeroRecords: 'Keine Einträge gefunden',
                paginate: {
                    previous:'Vorherige',
                    next: 'Nächste',
                    last: 'Letzte',
                    first: 'Erste'
                }
            },
            stateSave: true,
            responsive: true,
            lengthMenu: [
                [10, 30, 50, 100, -1],
                [10 , 30, 50, 100, 'Alle'] 
            ],

            columnDefs : [
                { 'targets': [0], 'orderable': false, 'searchable': false }
            ],
            order: [[ordercol, ascdesc]],
            iDisplayLength: num
        }
        
        var spec = $(this).attr('data-special');
        console.log(spec); 
        if(spec) { 
            var special = JSON.parse(decodeURIComponent(spec));
            attr.columnDefs.push(special);
        }
        
        $(this).DataTable(attr);
    }); 
});